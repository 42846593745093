<template>
  <div class="main">
    <div id="gjs"></div>
    <div id="blocks" class="side-bar" style="width: 100%"></div>
  </div>
</template>
<script>
  import "grapesjs/dist/css/grapes.min.css";
  import grapesjs from "grapesjs";
  import "grapesjs-preset-webpage";
  // Components
  import HeadingBuilderComponents from './BuilderComponents/HeadingBuilderComponents';
  import CardBuilderComponents from './BuilderComponents/CardBuilderComponents';
  import SectionBuilderComponents from './BuilderComponents/SectionBuilderComponents';
  import TableBuilderComponents from './BuilderComponents/TableBuilderComponents';
  import TextBuilderComponents from './BuilderComponents/TextBuilderComponents';
  import {EventBus} from '../utils/event-bus.js';

  export default {
    props: ["html", "resource"],
    data() {
      return {
        editor: null,
        entriesWithVideo: ['products', 'promotions', 'assets', 'projects', 'static-pages']
      };
    },
    methods: {
      uploadAsset(e) {
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        this.$http
          .get(
            `${process.env.VUE_APP_RESOURCES_V1_BASE_URL}/presigned-url`,
            {
              params: {
                resource_type: this.resource,
                content_type: files[0].type,
                cache_control: process.env.VUE_APP_CACHE_CONTROL
              }
            }
          )
          .then(response => {
            this.$http
              .put(response.data.url, files[0], {headers: {"Content-Type": files[0].type, "Cache-Control": process.env.VUE_APP_CACHE_CONTROL}})
              .then(res => {
                if (res.status === 200) {
                  let url = response.data.url.split("?")[0];
                  const assets = [
                    {
                      name: files[0].name,
                      src: url
                    }
                  ];
                  this.editor.AssetManager.add(assets);
                  this.editor.AssetManager.render();
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
      },
      setInitialStyles() {
        this.editor.setComponents('<div id="container" class="table row w-full"><div class="table-cell cell"></div></div>');
        this.editor.setStyle(`
      * {
          font-family: MyriadPro, Arial, sans-serif;
      }
      #container {
        height: 100%;
        padding-bottom: 100px;
      }
      `);

        if (this.resource === "discounts") {
          this.editor.setStyle(`
          * {
              font-family: MyriadPro, Arial, sans-serif;
          }
          #container {
            height: 100%;
            padding-bottom: 100px;
          }
        `)
        }

      }
    },
    mounted() {
      let showVideo = this.entriesWithVideo.includes(this.resource);
      EventBus.$on('saveBuilder', () => {
        this.editor.store();
      });
      this.editor = grapesjs.init({
        // Indicate where to init the editor. You can also pass an HTMLElement
        container: "#gjs",
        canvas: {
          styles: [
            'https://davivienda-resources.s3.amazonaws.com/2db1d7cc-92e2-492b-9f49-26d3776eb7c3.css'
          ]
        },
        // Get the content for the canvas directly from the element
        // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
        fromElement: true,
        // baseCss: 'html, body { background-color: black; }',
        // Size of the editor
        width: "100%",
        // Disable the storage manager for the moment
        storageManager: {type: "simple-storage", autosave: false},
        // Avoid any default panel
        panels: {defaults: []},
        // Add plugins
        plugins: ['gjs-preset-webpage'],
        pluginsOpts: {
          'gjs-preset-webpage': {
            blocksBasicOpts: {
              blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'image', showVideo ? 'video' : ''],
              blocksBasicOpts: false,
              showStylesOnChange: false,
            },
            navbarOpts: false,
            formsOpts: false,
            countdownOpts: false,
            textCleanCanvas: "¿Estás seguro de limpiar el lienzo?",
          },
        },
        blockManager: {
          appendTo: "#blocks",
        },
        assetManager: {
          disableUpload: false,
          uploadFile: this.uploadAsset,
          // assets : [
          //   { type: 'image', src : '../assets/svgs/svgs/account.svg', height:350, width:250},
          // ]
        },
        selectorManager: {
          escapeName: name => name.replace('/', '/')
        }
      });

      // Load Assets
      // this.editor.AssetManager.load({
      //   assets : [
      //     { type: 'image', src : 'https://davivienda-resources.s3.amazonaws.com/dev-hn/media-objects/2019/maxresdefault.jpg', height:350, width:250},
      //     { type: 'image', src : 'https://davivienda-resources.s3.amazonaws.com/dev-hn/media-objects/2019/75675995.jpg', height:350, width:250},
      //   ]
      // });

      // this.editor.assetManager.add(['http://img.jpg', '../assets/images/logo/logo.png']);

      // Clear canvas
      this.editor.Commands.add('canvas-clear', () => {
        if (confirm('¿Estás seguro de limpiar el lienzo?')) {
          this.editor.DomComponents.clear();
          this.setInitialStyles();
        }
      });

      this.editor.on('selector:add', selector => selector.set({
        active: false, // disable the selector
      }));

      // Set font in Typography Sector
      this.editor.on('load', () => {
        this.setInitialStyles();
        let styleManager = this.editor.StyleManager;
        let typographySector = styleManager.getSectors('Typography');
        let fontProperty = styleManager.getProperty('typography', 'font-family');
        let list = [];
        list.push({value: 'MyriadPro, Arial, sans-serif', name: 'MyriadPro'});
        fontProperty.set('list', list);
        fontProperty.set('defaults', 'MyriadPro, Arial, sans-serif');
        styleManager.render();
      });

      this.editor.runCommand("sw-visibility");
      const SimpleStorage = {};

      this.editor.StorageManager.add("simple-storage", {
        store(data, clb, clbErr) {
          let ret = {};
          for (let key in data) {
            ret[key] = data[key];
          }
          clb(ret);
        },
        load(keys, clb) {
          const result = {};
          keys.forEach(key => {
            const value = this.gjsData[key];
            if (value) {
              result[key] = value;
            }
          });
          clb(result);
        }
      });
      // this.editor.on("component:add", () => {
      //   this.editor.store();
      // });
      //
      // this.editor.on("component:remove", () => {
      //   this.editor.store();
      // });
      //
      // this.editor.on("component:update:src", () => {
      //   this.editor.store();
      // });
      // this.editor.on("component:styleUpdate:background-image", (data) => {
      //   this.editor.store();
      // });
      // this.editor.on("component:styleUpdate:background-size", (data) => {
      //   this.editor.store();
      // });
      // this.editor.on("component:styleUpdate:background-repeat", (data) => {
      //   this.editor.store();
      // });
      // this.editor.on("component:styleUpdate:background-position", (data) => {
      //   this.editor.store();
      // });
      this.editor.on("storage:store", data => {
        this.$emit("update:html", data);
      });

      // Para agregar bloques
      let blockManager = this.editor.BlockManager;

      // blockManager.getAll().remove('link-block')

      if (this.resource === "projects") {
        // Only to projects
        blockManager.add('sectionProjects', SectionBuilderComponents.sectionProjects);

      } else if (this.resource === "discounts") {
        // Only to discounts
        blockManager.add('sectionDiscounts', SectionBuilderComponents.sectionDiscounts);
        blockManager.add('sectionDiscountsSwapped', SectionBuilderComponents.sectionDiscountsSwapped);
      } else {
        // Heading
        blockManager.add('simpleHeading', HeadingBuilderComponents.simpleHeading);
        blockManager.add('descriptionHeading', HeadingBuilderComponents.descriptionHeading);
        // Cards
        blockManager.add('cardSimple', CardBuilderComponents.cardSimple);
        blockManager.add('cardSimpleImage', CardBuilderComponents.cardSimpleImage);
        blockManager.add('cardDouble', CardBuilderComponents.cardDouble);
        blockManager.add('cardDoubleImage', CardBuilderComponents.cardDoubleImage);
        blockManager.add('cardTriple', CardBuilderComponents.cardTriple);
        blockManager.add('cardTripleImage', CardBuilderComponents.cardTripleImage);
        blockManager.add('cardGroup', CardBuilderComponents.cardGroup);
        blockManager.add('cardGroupImage', CardBuilderComponents.cardGroupImage);
        blockManager.add('cardWithTripleIcons', CardBuilderComponents.cardWithTripleIcons);
        blockManager.add('cardWithMultipleIcons', CardBuilderComponents.cardWithMultipleIcons);
        blockManager.add('cardInfoTriple', CardBuilderComponents.cardInfoTriple);
        blockManager.add('cardInfoTripleVersion2', CardBuilderComponents.cardInfoTripleVersion2);
        blockManager.add('cardInfoTripleVersion3', CardBuilderComponents.cardInfoTripleVersion3);
        blockManager.add('cardWDate', CardBuilderComponents.cardWDate);
        blockManager.add('whiteLinkButton', CardBuilderComponents.whiteLinkButton);
        blockManager.add('redLinkButton', CardBuilderComponents.redLinkButton);
        blockManager.add('absWhiteLinkButton', CardBuilderComponents.absWhiteLinkButton);
        blockManager.add('absRedLinkButton', CardBuilderComponents.absRedLinkButton);
        blockManager.add('centerWhiteLinkButton', CardBuilderComponents.centerWhiteLinkButton);
        blockManager.add('centerRedLinkButton', CardBuilderComponents.centerRedLinkButton);
        blockManager.add('centerAbsWhiteLinkButton', CardBuilderComponents.centerAbsWhiteLinkButton);
        blockManager.add('centerAbsRedLinkButton', CardBuilderComponents.centerAbsRedLinkButton);
        // Tables
        blockManager.add('table', TableBuilderComponents.table);
        blockManager.add('tableDetail', TableBuilderComponents.tableDetail);
        // Sections
        blockManager.add('sectionSimple', SectionBuilderComponents.sectionSimple);
        blockManager.add('sectionSimpleDark', SectionBuilderComponents.sectionSimpleDark);
        blockManager.add('videoSection', SectionBuilderComponents.videoSection);
        blockManager.add('sectionDouble', SectionBuilderComponents.sectionDouble);
        blockManager.add('sectionDoubleSwapped', SectionBuilderComponents.sectionDoubleSwapped);
        blockManager.add('sectionTriple', SectionBuilderComponents.sectionTriple);
        blockManager.add('sectionTripleSwapped', SectionBuilderComponents.sectionTripleSwapped);
        blockManager.add('sectionWithImage', SectionBuilderComponents.sectionWithImage);
        blockManager.add('sectionWithImageSwapped', SectionBuilderComponents.sectionWithImageSwapped);
        blockManager.add('sectionBanner', SectionBuilderComponents.sectionBanner);
        blockManager.add('sectionBannerInformation', SectionBuilderComponents.sectionBannerInformation);
        blockManager.add('sectionWithImagePromotional', SectionBuilderComponents.sectionWithImagePromotional);
        blockManager.add('sectionWithImagePromotionalSwapped', SectionBuilderComponents.sectionWithImagePromotionalSwapped);
        // Texts
        blockManager.add('textWithIcon', TextBuilderComponents.textWithIcon);
        blockManager.add('textWithIconDoubleColumn', TextBuilderComponents.textWithIconDoubleColumn);
        blockManager.add('textSimple', TextBuilderComponents.textSimple);
        blockManager.add('textSimpleDoubleColumn', TextBuilderComponents.textSimpleDoubleColumn);

      }

      this.editor.BlockManager.getCategories().each(ctg => ctg.set('open', false));

    },
    watch: {
      html(newVal, oldVal) {
        this.editor.StorageManager.getStorages()["simple-storage"].gjsData = newVal;
        this.editor.load();

        if (newVal.styles === []) {
          this.setInitialStyles();
        }
      }
    }
  };
</script>
<style lang="css">
  #gjs {
    border: 2px solid #444;
  }

  .gjs-block {
    width: 100%;
    min-height: auto;
    border: none;
    border-radius: 0;
    padding: 0;
  }
</style>