const HeadingComponents = {
  // Heading
  'simpleHeading': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/title-with-icon.png"/>`,
    content: `<div class="pb-4 simple-heading-container">
                <img class="m-auto block simple-heading-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/shield-outline-red.svg" style="width: 40px; height: 30px" />
                <h1 class="text-center font-bold -mt-2 text-3xl text-gray-700 simple-heading">Seguridad</h1>
              </div>`,
    category: 'Encabezados',
  },

  'descriptionHeading': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/simple-title.png"/>`,
    content: `<div class="pb-4 description-heading-container">
                <h1 class="text-center font-bold text-3xl text-gray-700 description-heading">Beneficios</h1>
                <p class="text-center text-base -mt-2 description-heading-detail">Con el plan de ahorro programe sus pagos</p>
              </div>`,
    category: 'Encabezados',
  },

};

export default HeadingComponents;