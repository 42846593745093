const TextBuilderComponents = {
  // Texts
  'textWithIconDoubleColumn' : {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/descriptive-item-two-columns.png"/>`,
    content: `<div class="flex flex-wrap row w-full" style="margin: 20px 0;">
                <div class="sm:w-full sm:p-4 w-1/2 py-4 px-16 cell note-double-container">
                   <li class="sm:text-sm list-none py-0 pr-4 pl-6 mt-2 text-base text-justify note-double-description" style="background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Al contar con un DaBuenaVida, usted autómaticamente cuenta con el beneficio de Pago a Beneficiarios en caso de muerte del titular de la cuenta. Al momento de apertura usted designará sus benefiarios y el porcentaje que a cada uno corresponde. En caso de su fallecimiento, sus beneficiarios podrán optar a la devolución total del saldo de su cuenta a la fecha.</li>
                </div>
                <div class="sm:w-full sm:p-4 w-1/2 py-4 px-16 cell note-double-container">
                   <li class="sm:text-sm list-none py-0 pr-4 pl-6 mt-2 text-base text-justify note-double-description" style="background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Al contar con un DaBuenaVida, usted autómaticamente cuenta con el beneficio de Pago a Beneficiarios en caso de muerte del titular de la cuenta. Al momento de apertura usted designará sus benefiarios y el porcentaje que a cada uno corresponde. En caso de su fallecimiento, sus beneficiarios podrán optar a la devolución total del saldo de su cuenta a la fecha.</li>
                </div>
              </div>`,
    category: 'Textos',
  },

  'textWithIcon' : {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/descriptive-item.png"/>`,
    content: `<div class="flex flex-wrap row w-full" style="margin: 20px 0;">
                <div class="sm:w-full sm:p-4 w-full py-4 px-16 cell note-container">
                   <li class="sm:text-sm list-none py-0 pr-4 pl-6 mt-2 text-base text-justify note-description" style="background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Al contar con un DaBuenaVida, usted autómaticamente cuenta con el beneficio de Pago a Beneficiarios en caso de muerte del titular de la cuenta. Al momento de apertura usted designará sus benefiarios y el porcentaje que a cada uno corresponde. En caso de su fallecimiento, sus beneficiarios podrán optar a la devolución total del saldo de su cuenta a la fecha.</li>
                </div>
              </div>`,
    category: 'Textos',
  },

  'textSimple' : {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/descriptive-text.png"/>`,
    content: `<div class="flex flex-wrap row w-full" style="margin: 20px 0;">
                <div class="sm:w-full sm:p-4 w-full py-4 px-16 cell content-simple-container">
                  <p class="sm:text-sm list-none py-0 pr-4 pl-6 mt-2 text-base text-justify content-section-description">Al contar con un DaBuenaVida, usted autómaticamente cuenta con el beneficio de Pago a Beneficiarios en caso de muerte del titular de la cuenta. Al momento de apertura usted designará sus benefiarios y el porcentaje que a cada uno corresponde. En caso de su fallecimiento, sus beneficiarios podrán optar a la devolución total del saldo de su cuenta a la fecha.
                  </p>
                </div>
              </div>`,
    category: 'Textos',
  },

  'textSimpleDoubleColumn' : {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/description-two-columns.png"/>`,
    content: `<div class="flex flex-wrap row w-full" style="margin: 20px 0;">
                <div class="sm:w-full sm:p-4 w-1/2 py-4 px-16 cell content-double-container">
                  <p class="sm:text-sm list-none py-0 pr-4 pl-6 mt-2 text-base text-justify content-double-description">Al contar con un DaBuenaVida, usted autómaticamente cuenta con el beneficio de Pago a Beneficiarios en caso de muerte del titular de la cuenta. Al momento de apertura usted designará sus benefiarios y el porcentaje que a cada uno corresponde. En caso de su fallecimiento, sus beneficiarios podrán optar a la devolución total del saldo de su cuenta a la fecha.
                  </p>
                </div>
                <div class="sm:w-full sm:p-4 w-1/2 py-4 px-16 cell content-double-container">
                  <p class="sm:text-sm list-none py-0 pr-4 pl-6 mt-2 text-base text-justify content-double-description">Además, si usted sufre el extravío / Robo / Hurto de algún cheque, puede solicitar el NO PAGO en cualquiera de nuestra agencias a nivel nacional o llamando a nuestro Call Center.
                  </p>
                </div>
              </div>`,
    category: 'Textos',
  },
};

export default TextBuilderComponents;