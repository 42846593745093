const CardComponents = {
  // Cards
  'cardSimple': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/one-card.png"/>`,
    content: `<div class="flex flex-wrap items-stretch justify-center sm:px-4 row w-full py-0 px-12 card-simple-container">
                <div class="sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-simple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-simple-title" style="color: #606060;">Matrícula de autos</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
              </div>`,
    category: 'Tarjetas',
  },

  'cardDouble': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/two-cards.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 card-double-container">
                  <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-double-body" style="background-color: #ececec;">
                    <h3 class="sm:text-base text-xl font-bold mb-4 card-double-title" style="color: #606060;">Matrícula de autos</h3>
                    <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                  </div>
                  <div class="sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-double-body" style="background-color: #ececec;">
                    <h3 class="sm:text-base text-xl font-bold mb-4 card-double-title" style="color: #606060;">Mejoras a su vivienda</h3>
                    <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                  </div>
                </div>`,
    category: 'Tarjetas',
  },

  'cardTriple': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/three-cards.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 card-triple-container">
                <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-triple-body" style="background-color: #ececec;">
                  <h3 class=" sm:text-base text-xl font-bold mb-4 card-triple-title" style="color: #606060;">Matrícula de autos</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
                <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-triple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-triple-title" style="color: #606060;">Mejoras a su vivienda</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
                <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-triple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-triple-title" style="color: #606060;">Mejoras a su vivienda</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
              </div>`,
    category: 'Tarjetas',
  },

  'cardGroup': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/four-cards.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 card-multiple-container" style="color: #606060;">
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-multiple-title">Matrícula de autos</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-multiple-title">Mejoras a su vivienda</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-multiple-title">Pago de impuestos</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: #ececec;">
                  <h3 class="sm:text-base text-xl font-bold mb-4 card-multiple-title">Matrícula y gastos de educación</h3>
                  <img style="bottom: 1rem; left: 1rem; width: 30px; height: 30px" class="absolute card-simple-icon" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle-gray.svg" />
                </div>
              </div>`,
    category: 'Tarjetas',
  },
  'cardSimpleImage': {
    label: `Simple card with image`,
    content: `<div class="flex flex-wrap items-stretch justify-center sm:px-4 row w-full py-0 px-12 card-simple-container">
                <div class="md:w-1/2 cell w-1/4 relative card-simple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0">
                        </div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>
                </div>
             </div>`,
    category: 'Tarjetas',
  },

  'cardDoubleImage': {
    label: `Two cards with image`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 card-double-container">
                  <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-double-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>
                  </div>
                  <div class="sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-double-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>
                  </div>
                </div>`,
    category: 'Tarjetas',
  },

  'cardTripleImage': {
    label: `Three cards with image`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 card-triple-container">
                <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-triple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>  
                </div>
                <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-triple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>                  
                </div>
                <div class="sm:block sm:w-full  sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-triple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>                 
                 </div>
              </div>`,
    category: 'Tarjetas',
  },

  'cardGroupImage': {
    label: `Four cards with image`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 card-multiple-container" style="color: #606060;">
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>  
                </div>
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>  
                </div>
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>  
                </div>
                <div class="sm:block sm:w-full sm:pr-4 sm:pl-4 cell w-1/4 relative pt-8 pr-6 pb-12 pl-6 border-8 border-solid border-white card-multiple-body" style="background-color: transparent; padding: 0">
                    <div class="builder-small-card shadow-lg w-full appearing">
                      <div class="small-card-image">
                        <div class="builder-transition-img" style="background-size: cover;  background-position: center; background-image: url('https://via.placeholder.com/210x220'); padding: 0"></div>
                      </div>
                      <div class="px-2 font-bold text-xl leading-tight small-card-description">
                        <p class="small-card-name">Lorem Ipsum</p>
                        <div class="small-card-info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget sodales mauris. Integer at justo vel nulla luctus tristique. Fusce ante massa, ullamcorper eu luctus tincidunt, imperdiet nec magna.</div>
                      </div>
                    </div>  
                </div>
              </div>`,
    category: 'Tarjetas',
  },

  'cardWithTripleIcons': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/icon-with-card-horizontal.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch justify-center sm:px-4 w-full row py-0 px-12 image-card-triple-container">
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white image-card-triple-elements">
                  <div class="flex flex-wrap items-center justify-center h-full border-b-solid border-b-4 border-red-500 relative shadow-md image-card-triple-body">
                    <img class="image-card-triple-image mr-4" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem" />
                    <h3 class="sm:text-base text-xl font-bold image-card-triple-title">Banca por Internet Personal</h3>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white image-card-triple-elements">
                  <div class="flex flex-wrap items-center justify-center h-full border-b-solid border-b-4 border-red-500 relative shadow-md image-card-triple-body">
                    <img class="image-card-triple-image mr-4" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem"/>
                    <h3 class="sm:text-base text-xl font-bold image-card-triple-title">Banca por Internet Empresarial</h3>
                   </div>
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white image-card-triple-elements">
                  <div class="flex flex-wrap items-center justify-center h-full border-b-solid border-b-4 border-red-500 relative shadow-md image-card-triple-body">
                    <img class="image-card-triple-image mr-4" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem"/>
                    <h3 class="sm:text-base text-xl font-bold image-card-triple-title">Davivienda Empresarial Multilatina</h3>
                  </div>
                </div>
              </div>`,
    category: 'Tarjetas',
  },

  'cardWithMultipleIcons': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/icon-with-card-vertical.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch sm:px-4 w-full row py-0 px-12 card-multiple-icon-container">
                <div class="sm:w-full cell w-1/4 relative border-8 border-solid border-white card-multiple-icon-elements">
                  <div class="text-center shadow-md w-full h-full p-6 align-middle relative card-multiple-icon-body">
                    <img class="m-auto card-multiple-icon-image" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem"/>
                    <h3 class="sm:text-base text-xl font-bold card-multiple-icon-title">Compras en Línea</h3>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/4 relative border-8 border-solid border-white card-multiple-icon-elements">
                  <div class="text-center shadow-md w-full h-full p-6 align-middle relative card-multiple-icon-body">
                    <img class="m-auto card-multiple-icon-image" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem"/>
                    <h3 class="sm:text-base text-xl font-bold card-multiple-icon-title">Reserva de hoteles</h3>
                   </div>
                </div>
                <div class="sm:w-full cell w-1/4 relative border-8 border-solid border-white card-multiple-icon-elements">
                  <div class="text-center shadow-md w-full h-full p-6 align-middle relative card-multiple-icon-body">
                    <img class="m-auto card-multiple-icon-image" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem"/>
                    <h3 class="sm:text-base text-xl font-bold card-multiple-icon-title">Cambio de moneda</h3>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/4 relative border-8 border-solid border-white card-multiple-icon-elements">
                  <div class="text-center shadow-md w-full h-full p-6 align-middle relative card-multiple-icon-body">
                    <img class="m-auto card-multiple-icon-image" src="https://via.placeholder.com/70x70" style="width: 70px; height: 70px; left: 1rem"/>
                    <h3 class="sm:text-base text-xl font-bold card-multiple-icon-title">Viajes</h3>
                  </div>
                </div>
              </div>`,
    category: 'Tarjetas',
  },

  'cardInfoTriple': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/info-cards.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch sm:px-4 w-full row py-0 px-12 card-info-container">
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-red-600 relative w-full h-full bg-no-repeat p-6 text-white card-contact-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px; background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/phone-light.svg');">
                    <div class="card-info-content-white">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Contacto</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Nombre</p>
                        <p class="text-lg font-bold card-info-value">José Fabricio Rivera</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Teléfono</p>
                        <p class="text-lg font-bold card-info-value">2240-0909 ext 384182</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Correo</p>
                        <p class="text-lg font-bold card-info-value">fabricio@davivienda.com.hn</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-gray-700 relative w-full h-full bg-no-repeat p-6 text-white card-address-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px; background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location-pin-outline-light.svg');">
                    <div class="card-info-content-white">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Dirección</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg font-bold card-info-value-bold">Centro comercial Multiplaza, 1er nivel, contiguo a supermercados paíz, Tegucigalpa, Francisco Morazán</p>
                      </div>
                    </div> 
                  </div>
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-gray-300 text-gray-700 relative w-full h-full p-6 bg-no-repeat card-support-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px;background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/call-center-light.svg');">
                    <div class="card-info-content-gray">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Atención al Cliente</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Teléfono</p>
                        <p class="text-lg font-bold card-info-value">2240-0909 ext 384182</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Horario</p>
                        <p class="text-lg font-bold card-info-value">8:00 am - 6:00 pm</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`,
    category: 'Tarjetas',
  },
  'cardInfoTripleVersion2': {
    label: 'Tripe Card Swapped L-D-R',
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch sm:px-4 w-full row py-0 px-12 card-info-container">
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-gray-300 text-gray-700 relative w-full h-full p-6 bg-no-repeat card-support-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px;background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/call-center-light.svg');">
                    <div class="card-info-content-gray">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Atención al Cliente</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Teléfono</p>
                        <p class="text-lg font-bold card-info-value">2240-0909 ext 384182</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Horario</p>
                        <p class="text-lg font-bold card-info-value">8:00 am - 6:00 pm</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-gray-700 relative w-full h-full bg-no-repeat p-6 text-white card-address-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px; background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location-pin-outline-light.svg');">
                    <div class="card-info-content-white">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Dirección</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg font-bold card-info-value-bold">Centro comercial Multiplaza, 1er nivel, contiguo a supermercados paíz, Tegucigalpa, Francisco Morazán</p>
                      </div>
                    </div> 
                  </div>
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-red-600 relative w-full h-full bg-no-repeat p-6 text-white card-contact-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px; background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/phone-light.svg');">
                    <div class="card-info-content-white">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Contacto</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Nombre</p>
                        <p class="text-lg font-bold card-info-value">José Fabricio Rivera</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Teléfono</p>
                        <p class="text-lg font-bold card-info-value">2240-0909 ext 384182</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Correo</p>
                        <p class="text-lg font-bold card-info-value">fabricio@davivienda.com.hn</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`,
    category: 'Tarjetas',
  },
  'cardInfoTripleVersion3': {
    label: 'Tripe Card Swapped L-R-D',
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch sm:px-4 w-full row py-0 px-12 card-info-container">
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-gray-300 text-gray-700 relative w-full h-full p-6 bg-no-repeat card-support-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px;background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/call-center-light.svg');">
                    <div class="card-info-content-gray">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Atención al Cliente</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Teléfono</p>
                        <p class="text-lg font-bold card-info-value">2240-0909 ext 384182</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Horario</p>
                        <p class="text-lg font-bold card-info-value">8:00 am - 6:00 pm</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-gray-700 relative w-full h-full bg-no-repeat p-6 text-white card-contact-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px; background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location-pin-outline-light.svg');">
                    <div class="card-info-content-white">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Dirección</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg font-bold card-info-value-bold">Centro comercial Multiplaza, 1er nivel, contiguo a supermercados paíz, Tegucigalpa, Francisco Morazán</p>
                      </div>
                    </div> 
                  </div>
                </div>
                <div class="sm:w-full cell w-1/3 relative border-8 border-solid border-white card-info-elements">
                  <div class="bg-red-600 relative w-full h-full bg-no-repeat p-6 text-white card-address-body card-info-image" style="background-position: calc(100% + 70px) top; background-size: 220px 220px; background-image: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/phone-light.svg');">
                    <div class="card-info-content-white">
                      <p class="font-bold uppercase leading-none text-sm card-info-title">Contacto</p>  
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Nombre</p>
                        <p class="text-lg font-bold card-info-value">José Fabricio Rivera</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Teléfono</p>
                        <p class="text-lg font-bold card-info-value">2240-0909 ext 384182</p>
                      </div>
                      <div class="mt-3 card-info-description">
                        <p class="text-lg card-info-label">Correo</p>
                        <p class="text-lg font-bold card-info-value">fabricio@davivienda.com.hn</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`,
    category: 'Tarjetas',
  },
  'cardWDate': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/month-cards.png"/>`,
    content: `<div class="flex flex-wrap md:flex-wrap items-stretch sm:px-4 w-full row py-0 px-12 card-date-container">
                <div class="sm:w-full cell w-1/5 relative border-8 border-solid border-white card-date-elements">
                  <div class="text-center shadow-md p-6 card-date-body">
                    <p class="font-bold text-red-600 text-6xl card-date-title">12</p>
                    <p class="text-2xl font-bold text-color-black uppercase card-date-value">Meses</p>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/5 relative border-8 border-solid border-white card-date-elements">
                  <div class="text-center shadow-md p-6 card-date-body">
                    <p class="font-bold text-red-600 text-6xl card-date-title">24</p>
                    <p class="text-2xl font-bold text-color-black uppercase card-date-value">Meses</p>
                   </div>
                </div>
                <div class="sm:w-full cell w-1/5 relative border-8 border-solid border-white card-date-elements">
                  <div class="text-center shadow-md p-6 card-date-body">
                    <p class="font-bold text-red-600 text-6xl card-date-title">36</p>
                    <p class="text-2xl font-bold text-color-black uppercase card-date-value">Meses</p>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/5 relative border-8 border-solid border-white card-date-elements">
                  <div class="text-center shadow-md p-6 card-date-body">
                    <p class="font-bold text-red-600 text-6xl card-date-title">48</p>
                    <p class="text-2xl font-bold text-black uppercase card-date-value">Meses</p>
                  </div>
                </div>
                <div class="sm:w-full cell w-1/5 relative border-8 border-solid border-white card-date-elements">
                  <div class="text-center shadow-md p-6 card-date-body">
                    <p class="font-bold text-red-600 text-6xl card-date-title">60</p>
                    <p class="text-2xl font-bold text-color-black uppercase card-date-value">Meses</p>
                  </div>
                </div>
              </div>`,
    category: 'Tarjetas',
  },
  'whiteLinkButton': {
    label: 'White Link Button',
    content: `<a class="text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info white-info">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'redLinkButton': {
    label: 'Red Link Button',
    content: `<a class="text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info red-info">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'absWhiteLinkButton': {
    label: 'Abs White Link Button',
    content: `<a class="text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info white-info abs">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'absRedLinkButton': {
    label: 'Abs Red Link Button',
    content: `<a class="text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info red-info abs">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'centerWhiteLinkButton': {
    label: 'Centered White Link Button',
    content: `<a class="reset-float text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info white-info" style="float: none; position: relative; left: 50%; transform: translateX(-50%)">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'centerRedLinkButton': {
    label: 'Centered Red Link Button',
    content: `<a class="reset-float text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info red-info" style="float: none; position: relative; left: 50%; transform: translateX(-50%)">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'centerAbsWhiteLinkButton': {
    label: 'Centered Abs White Link Button',
    content: `<a class="text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info white-info" style="float: none; position: absolute; left: 50%; bottom: 15px; transform: translateX(-50%)">Ver Más</a>`,
    category: 'Tarjetas',
  },
  'centerAbsRedLinkButton': {
    label: 'Centered Abs Red Link Button',
    content: `<a class="text-white bg-red-600 text-sm uppercase font-bold text-center p-2 rounded-full cursor-pointer more-info red-info" style="float: none; position: absolute; left: 50%;bottom: 15px; transform: translateX(-50%)">Ver Más</a>`,
    category: 'Tarjetas',
  }
};

export default CardComponents;