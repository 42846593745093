const TableBuilderComponents = {
  // Tables
  'table': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/simple-table.png"/>`,
    content: `<div class="sm:p-1 flex flex-wrap p-16 row w-full simple-table-container">
                <table class="sm:block h-auto w-full border-collapse simple-table">
                  <thead class="simple-table-heading">
                    <tr class="border-b-2 border-gray-300">
                      <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Título de ahorro</span></th>
                      <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Plan de ahorro</span></th>
                      <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Cuota mensual</span></th>
                      <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Ganará desde hasta</span></th>
                      <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Monto premio</span></th>
                    </tr>                    
                  </thead>
                  <tbody class="simple-table-body">
                      <tr class="border-b-2 border-gray-300">
                        <td class="p-3 text-base"><span>L 5,000.00</span></td>
                        <td class="p-3 text-base"><span>12</span></td>
                        <td class="p-3 text-base"><span>L 416.67</span></td>
                        <td class="p-3 text-base"><span>24 veces</span></td>
                        <td class="p-3 text-base"><span>L 10,000.00</span></td>
                      </tr>
                      <tr class="border-b-2 border-gray-300">
                        <td class="p-3 text-base"><span>L 5,000.00</span></td>
                        <td class="p-3 text-base"><span>12</span></td>
                        <td class="p-3 text-base"><span>L 416.67</span></td>
                        <td class="p-3 text-base"><span>24 veces</span></td>
                        <td class="p-3 text-base"><span>L 10,000.00</span></td>
                      </tr>
                      <tr class="border-b-2 border-gray-300">
                        <td class="p-3 text-base"><span>L 5,000.00</span></td>
                        <td class="p-3 text-base"><span>12</span></td>
                        <td class="p-3 text-base"><span>L 416.67</span></td>
                        <td class="p-3 text-base"><span>24 veces</span></td>
                        <td class="p-3 text-base"><span>L 10,000.00</span></td>
                      </tr>
                      <tr class="border-b-2 border-gray-300">
                        <td class="p-3 text-base"><span>L 5,000.00</span></td>
                        <td class="p-3 text-base"><span>12</span></td>
                        <td class="p-3 text-base"><span>L 416.67</span></td>
                        <td class="p-3 text-base"><span>24 veces</span></td>
                        <td class="p-3 text-base"><span>L 10,000.00</span></td>
                      </tr>
                  </tbody>
                </table>
              </div>`,
    category: 'Tablas',
  },

  'tableDetail': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/detail-table.png"/>`,
    content: `<div class="sm:p-1 flex flex-wrap p-16 row w-full detail-table-container">
                <div class="sm:w-full w-1/3 h-full cell detail-table-description">
                  <div class="p-6 details">
                    <h2 class="text-3xl font-bold leading-none p-2 -my-2 mx-0 text-red-600 detail-table-title">Préstamos automáticos sobre el plan de ahorro DaBuenaVida</h2>
                    <p class="p-2 -mb-2 text-lg text-gray-700 detail-table-summary">Premios desde 24 hasta 108 veces su cuota mensual ahorrada</p>
                    <p class="p-2 -mb-2 text-lg text-gray-700 detail-table-summary">Participación ilimitada en sorteos durante la vigilacia del plan de ahorro</p>
                  </div>
                </div>
                <div class="sm:w-full w-2/3 h-auto p-2 cell detail-table-content">
                  <table class="h-auto w-full p-2 border-collapse detail-table">
                    <thead class="detail-table-heading">
                      <tr class="border-b-2 border-gray-300">
                        <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Título de ahorro</span></th>
                        <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Plan de ahorro</span></th>
                        <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Cuota mensual</span></th>
                        <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Ganará desde hasta</span></th>
                        <th class="sm:text-sm sm:py-2 text-left text-xl py-5 px-3 border-b-2 border-gray-700"><span>Monto premio</span></th>
                      </tr>
                    </thead>
                    <tbody class="detail-table-body">
                        <tr class="border-b-2 border-gray-300">
                          <td class="p-3 text-base"><span>L 5,000.00</span></td>
                          <td class="p-3 text-base"><span>12</span></td>
                          <td class="p-3 text-base"><span>L 416.67</span></td>
                          <td class="p-3 text-base"><span>24 veces</span></td>
                          <td class="p-3 text-base"><span>L 10,000.00</span></td>
                        </tr>
                        <tr class="border-b-2 border-gray-300">
                          <td class="p-3 text-base"><span>L 5,000.00</span></td>
                          <td class="p-3 text-base"><span>12</span></td>
                          <td class="p-3 text-base"><span>L 416.67</span></td>
                          <td class="p-3 text-base"><span>24 veces</span></td>
                          <td class="p-3 text-base"><span>L 10,000.00</span></td>
                        </tr>
                        <tr class="border-b-2 border-gray-300">
                          <td class="p-3 text-base"><span>L 5,000.00</span></td>
                          <td class="p-3 text-base"><span>12</span></td>
                          <td class="p-3 text-base"><span>L 416.67</span></td>
                          <td class="p-3 text-base"><span>24 veces</span></td>
                          <td class="p-3 text-base"><span>L 10,000.00</span></td>
                        </tr>
                        <tr class="border-b-2 border-gray-300">
                          <td class="p-3 text-base"><span>L 5,000.00</span></td>
                          <td class="p-3 text-base"><span>12</span></td>
                          <td class="p-3 text-base"><span>L 416.67</span></td>
                          <td class="p-3 text-base"><span>24 veces</span></td>
                          <td class="p-3 text-base"><span>L 10,000.00</span></td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>`,
    category: 'Tablas',
    draggable: ['tr'],
  },

};

export default TableBuilderComponents;