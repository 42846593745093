const SectionBuilderComponents = {
  // Sections
  'sectionSimple': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/simple-section.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="w-full my-5 mx-0 simple-section-container">
                  <div class="bg-gray-300 w-full py-6 px-8 relative simple-section-content">                    
                    <h1 class="text-2xl leading-none font-bold text-gray-700 simple-section-title">Caracteristicas</h1>
                    <ul class="mt-2 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Montos desde L. 5,0000.00 hasta L. 500,000.00</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Plazos de ahorro de 12, 24 y 36 meses</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Moneda: Lempiras</li>
                    </ul>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionSimpleDark': {
    label: `Dark Simple Section`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="w-full my-5 mx-0 simple-section-container">
                  <div class="bg-gray-700 w-full py-6 px-8 relative simple-section-dark">                    
                    <h1 class="text-2xl leading-none font-bold text-white simple-section-title">Caracteristicas</h1>
                    <ul class="mt-2 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Montos desde L. 5,0000.00 hasta L. 500,000.00</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Plazos de ahorro de 12, 24 y 36 meses</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Moneda: Lempiras</li>
                    </ul>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'videoSection': {
    label: `Video`,
    content: `<div class="flex flex-wrap row w-full p-0 video-row">
                <div class="w-full simple-section-container">
                  <div class="pb-4 description-heading-container">
                    <h1 class="text-center font-bold text-3xl text-gray-700 description-heading">Video</h1>
                    <p class="text-center text-base -mt-2 description-heading-detail">Video Interesante</p>
                  </div>
                  <div class="w-full my-5 mx-0" style="min-height: 250px; min-width: 300px">
                      <video allowfullscreen="allowfullscreen" src="img/video2.webm" controls="controls" class="m-auto" style="min-height: 250px; min-width: 300px">
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionDouble': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/double-section.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full my-5 mx-0 double-section-content">
                   <div class="bg-gray-300 sm:w-full w-1/2 py-6 px-8 text-gray-700 cell relative double-section-color-primary">
                    <h2 class="text-2xl leading-none font-bold double-section-title-primary">Caracteristicas</h2>
                    <ul class="mt-2 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Montos desde L. 5,0000.00 hasta L. 500,000.00</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Plazos de ahorro de 12, 24 y 36 meses</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Moneda: Lempiras</li>
                    </ul>
                  </div>
                  <div class="bg-gray-700 sm:w-full w-1/2 py-6 px-8 text-white cell relative double-section-color-secondary">
                    <h2 class="text-2xl leading-none font-bold double-section-title-secondary">Para aperturar su DaBuenaVida, usted deberá cumplir los siguientes requisitos</h2>
                    <ul class="mt-2 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Aperturar y/o mantener durante la vigencia del Título de Capitalización una cuenta de ahorro o de cheques en Banco Davivienda S.A. los requisitos que aplican son lo vigentes actualmente tanto para persona natural como para persona jurídica</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                    </ul>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionDoubleSwapped': {
    label: `Double Dark - Light`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full my-5 mx-0 double-section-content">
                    <div class="bg-gray-700 sm:w-full w-1/2 py-6 px-8 text-white cell relative double-section-color-secondary">
                        <h2 class="text-2xl leading-none font-bold double-section-title-secondary">Para aperturar su DaBuenaVida, usted deberá cumplir los siguientes requisitos</h2>
                        <ul class="my-8 mx-4">
                          <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Aperturar y/o mantener durante la vigencia del Título de Capitalización una cuenta de ahorro o de cheques en Banco Davivienda S.A. los requisitos que aplican son lo vigentes actualmente tanto para persona natural como para persona jurídica</li>
                          <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                          <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                        </ul>
                    </div>
                   <div class="bg-gray-300 sm:w-full w-1/2 py-6 px-8 text-gray-700 cell relative double-section-color-primary">
                    <h2 class="text-2xl leading-none font-bold double-section-title-primary">Caracteristicas</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Montos desde L. 5,0000.00 hasta L. 500,000.00</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Plazos de ahorro de 12, 24 y 36 meses</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Moneda: Lempiras</li>
                    </ul>
                  </div>
 
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionTriple': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/triple-section.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full my-5 mx-0 triple-section-content">
                  <div class="bg-gray-300 sm:w-full w-1/3 py-6 px-8 text-gray-700 cell relative triple-section-color-primary">                    
                    <h2 class="text-2xl leading-none font-bold triple-section-title-primary">Caracteristicas</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Montos desde L. 5,0000.00 hasta L. 500,000.00</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Plazos de ahorro de 12, 24 y 36 meses</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Moneda: Lempiras</li>
                    </ul>
                  </div>
                  <div class="bg-gray-700 sm:w-full w-1/3 py-6 px-8 text-white cell relative triple-section-color-secondary">
                    <h2 class="text-2xl leading-none font-bold triple-section-title-secondary">Requisitos</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Aperturar y/o mantener durante la vigencia del Título de Capitalización una cuenta de ahorro o de cheques en Banco Davivienda S.A. los requisitos que aplican son lo vigentes actualmente tanto para persona natural como para persona jurídica</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                    </ul>
                  </div>
                  <div class="bg-gray-300 sm:w-full w-1/3 py-6 px-8 text-gray-700 cell relative triple-section-color-primary">
                    <h2 class="text-2xl leading-none font-bold triple-section-title-primary">Para aperturar su DaBuenaVida, usted deberá cumplir los siguientes requisitos</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                    </ul>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },

  'sectionTripleSwapped': {
    label: `Triple Section Swapped`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full my-5 mx-0 triple-section-content">
                  <div class="bg-gray-700 sm:w-full w-1/3 py-6 px-8 text-white cell relative triple-section-color-secondary" >                    
                    <h2 class="text-2xl leading-none font-bold triple-section-title-secondary">Caracteristicas</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Montos desde L. 5,0000.00 hasta L. 500,000.00</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Plazos de ahorro de 12, 24 y 36 meses</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Moneda: Lempiras</li>
                    </ul>
                  </div>
                  <div class="bg-gray-300 sm:w-full w-1/3 py-6 px-8 text-gray-700 cell relative triple-section-color-primary">
                    <h2 class="text-2xl leading-none font-bold triple-section-title-primary">Requisitos</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Aperturar y/o mantener durante la vigencia del Título de Capitalización una cuenta de ahorro o de cheques en Banco Davivienda S.A. los requisitos que aplican son lo vigentes actualmente tanto para persona natural como para persona jurídica</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                    </ul>
                  </div>
                  <div class="bg-gray-700 sm:w-full w-1/3 py-6 px-8 text-white cell relative triple-section-color-secondary" >
                    <h2 class="text-2xl leading-none font-bold triple-section-title-secondary">Para aperturar su DaBuenaVida, usted deberá cumplir los siguientes requisitos</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                    </ul>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionWithImage': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/section-with-image.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full my-5 mx-0 bg-gray-300 image-section-content" style="min-height: 400px">
                   <div class="sm:w-full sm:h-auto h-full pt-8 pr-12 pb-8 pl-12 text-gray-700 w-1/2 cell relative image-section-primary">
                      <h2 class="text-3xl leading-none font-bold image-section-title">Caracteristicas</h2>
                      <ul class="mt-2 mx-4">
                        <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                        <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                      </ul>
                  </div>
                  <div class="sm:min-h-1/4 sm:w-full bg-gray-700 w-1/2 cell image-section-secondary" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-image: url('https://via.placeholder.com/700x400')">
                   
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionWithImageSwapped': {
    label: `Section with image swapped`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full my-5 mx-0 bg-gray-300 image-section-content" style="min-height: 400px">
                    <div class="sm:min-h-1/4 sm:w-full bg-gray-700 w-1/2 cell image-section-secondary" style="background-size: cover; background-repeat: no-repeat; background-position: center;background-image: url('https://via.placeholder.com/700x400')">
                
                    </div>
                   <div class="sm:w-full pt-8 pr-12 pb-8 pl-12 text-gray-700 w-1/2 cell relative image-section-primary">
                    <h2 class="text-3xl leading-none font-bold image-section-title">Caracteristicas</h2>
                    <ul class="my-8 mx-4">
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Solicitud de plan de ahorro</li>
                      <li class="list-none mt-2 font-base pt-0 pr-4 pb-0 pl-6" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Pago de primera cuota</li>
                    </ul>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionBanner': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/informational-banner.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0" style="min-height: 400px;">
                <div class="flex flex-wrap sm:flex-wrap w-full my-2 mx-0 bg-gray-300 banner-section-content" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-image: url('http://via.placeholder.com/1600x500')">
                  <div class="flex flex-col justify-center pt-12 pr-16 pb-24 pl-16 w-full text-white cell banner-section-primary">
                    <img src="https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/financial-movements-white.svg" alt="Financial" width="100px" height="100px">
                    <h1 class="font-bold leading-none text-3xl banner-section-title">Maneje sus finanzas con los productos y servicios de Banca Personal</h1>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },

  'sectionBannerInformation': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/help-banner.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="bg-red-600 w-full my-2 mx-0 banner-info-section-content">
                  <div class="py-32 px-3 text-white h-full text-center cell relative banner-info-section-primary">
                    <h1 class="-m-2 text-4xl leading-none font-bold banner-info-section-title">Consultas</h1>
                    <div class="mt-1 banner-info-section-description">
                      <p class="text-2xl font-bold banner-info-section-detail">Consulte su disponible de financimiento llamando a los teléfonos 2286-1919 en Tegucigalpa, al 2514-1919 en San Pedro Sula y 2480-1919 en La Ceiba</p>
                    </div>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },

  'sectionWithImagePromotional': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/promotional-banner.png"/>`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full h-auto bg-gray-300 my-5 mx-0 image-section-promotional-content" style="min-height: 400px">
                  <div class="sm:w-full bg-red-600 py-32 px-16 text-white w-1/3 cell relative image-section-promotional-primary">
                    <h2 class="text-3xl leading-none font-bold image-section-promotional-title">Con nuestro financiamiento en efectivo disfrute la vida</h2>
                    <div class="mt-2 image-section-promotional-description">
                      <p class="text-xl image-section-promotional-detail">Reciba fácil y rápido el dinero que necesita</p>
                    </div>
                  </div>
                  <div class="sm:min-h-1/4 sm:w-full bg-gray-700 w-2/3 cell image-section-promotional-secondary" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-image: url('https://via.placeholder.com/700x400')">
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },
  'sectionWithImagePromotionalSwapped': {
    label: `Section With Promotional Image Swapped`,
    content: `<div class="flex flex-wrap row w-full p-0">
                <div class="flex flex-wrap sm:flex-wrap w-full h-auto bg-gray-300 my-5 mx-0 image-section-promotional-content" style="min-height: 400px">
                <div class="sm:min-h-1/4 sm:w-full bg-gray-700 w-2/3 cell image-section-promotional-secondary" style="background-size: cover; background-repeat: no-repeat; background-position: center;background-image: url('https://via.placeholder.com/700x400')">
                  </div>
                  <div class="sm:w-full bg-red-600 py-32 px-16 text-white w-1/3 cell relative image-section-promotional-primary">
                    <h2 class="text-3xl leading-none font-bold image-section-promotional-title">Con nuestro financiamiento en efectivo disfrute la vida</h2>
                    <div class="mt-2 image-section-promotional-description">
                      <p class="text-xl image-section-promotional-detail">Reciba fácil y rápido el dinero que necesita</p>
                    </div>
                  </div>
                </div>
              </div>`,
    category: 'Secciones',
  },

  'sectionProjects': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/projects-section.png"/>`,
    content: `<div class="bg-gray-300 project-detail-table">
                <div class="cell pt-4 w-1/4 project-table-description">
                  <div class="p-5 project-table-heading">
                    <h1 class="text-2xl font-bold text-gray-700 project-title">Modelo Fresno</h1>
                  </div>
                </div>
                <div class="w-full h-auto cell project-table-content">
                  <table class="w-full border-collapse project-table">
                    <tbody>
                        <tr class="last:border-0 border-b-2 border-gray-300">
                          <th class="w-2/5 text-left align-top pt-5 pr-0 pb-0 pl-4">
                            <li class="list-none mt-2 pt-0 pr-4 pb-0 pl-6 text-base project-table-title" style="background-size: 20px; background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Precio de Venta</li>
                          </th>
                          <td class="w-3/5 py-5 px-4">
                            <p class="text-base font-bold project-table-detail">US $194,204.00</p>
                            <p class="text-base font-bold project-table-detail">Los precios de venta están sujetos a variación</p>
                          </td>
                        </tr>
                        <tr class="last:border-0 border-b-2 border-gray-300">
                          <th class="w-2/5 text-left align-top pt-5 pr-0 pb-0 pl-4">
                            <li class="list-none mt-2 pt-0 pr-4 pb-0 pl-6 text-base project-table-title" style="background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Área de terreno</li>
                          </th>
                          <td class="w-3/5 py-5 px-4">
                            <p class="text-base font-bold project-table-detail">304.78 vrs2</p>
                          </td>
                        </tr>
                        <tr class="last:border-0 border-b-2 border-gray-300">
                          <th class="w-2/5 text-left align-top pt-5 pr-0 pb-0 pl-4">
                            <li class="list-none mt-2 pt-0 pr-4 pb-0 pl-6 text-base project-table-title" style="background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Metros de construcción</li>
                          </th>
                          <td class="w-3/5 py-5 px-4">
                            <p class="text-base font-bold project-table-detail">186 mts2</p>
                          </td>
                        </tr>
                        <tr class="last:border-0 border-b-2 border-gray-300">
                          <th class="w-2/5 text-left align-top pt-5 pr-0 pb-0 pl-4">
                            <li class="list-none mt-2 pt-0 pr-4 pb-0 pl-6 text-base project-table-title" style="background: url('https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left-red.svg') no-repeat left top/20px;">Descripción</li>
                          </th>
                          <td class="w-3/5 py-5 px-4">
                            <p class="text-base font-bold project-table-detail">Primera planta: Sala, cocina, comedor, habitación con baño, medio baño, terraza interio, área de jardín, área de lavandería, dormitorio de servicio con baño</p>
                            <p class="text-base font-bold project-table-detail">Segunda planta: Sala familiar, 3 habitaciones, 2 baños, walk-in closet en habitación principal y habitación 2, closet e habitación 3, terraza en habitación principal</p>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>`,
    category: 'Secciones',
    draggable: ['tr'],
  },

  'sectionDiscounts': {
    label: `<img style="width: 100%; height: 100%;" src="https://davivienda-resources.s3.amazonaws.com/cms/builder/components/discounts-section.png"/>`,
    content: `<div class="flex flex-wrap sm:flex-wrap row w-full p-0 discounts-section-container" style="min-height: 400px">
                  <div class="bg-transparent sm:w-full w-1/3 cell card-info-elements">
                    <div class="bg-red-600 text-white pt-6 pr-24 w-full h-full pb-6 pl-8 card-contact-body card-info-image">
                      <div class="card-info-content-white">
                        <p class="text-base font-bold leading-none uppercase card-info-title">Información</p>  
                        <div class="mt-2 card-info-description">
                          <p class="text-xl font-bold card-info-value-bold">Centro Comercial Los Castaños, 2do Piso. Francisco Morazán, Distrito Central Centro Comercial Los Castaños</p>
                        </div>
                        <div class="mt-2 card-info-description">
                          <p class="text-xl card-info-label">Teléfono</p>
                          <p class="text-xl -mt-2 card-info-value">2240-0909 ext 384182</p>
                        </div>
                        <div class="mt-2 card-info-description">
                          <p class="text-xl card-info-label">Validez de la promoción</p>
                          <p class="text-xl -mt-2 card-info-value">01/08/2019 al 31/12/2019</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="sm:min-h-1/4 pt-8 pr-6 pb-12 pl-6 sm:w-full bg-red-600 w-3/5 cell discounts-section-body" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-image: url('https://via.placeholder.com/700x400')">
                  </div>
                </div>`,
    category: 'Secciones',
  },
  'sectionDiscountsSwapped': {
    label: `Swapped Version`,
    content: `<div class="flex flex-wrap sm:flex-wrap row w-full p-0 discounts-section-container" style="min-height: 400px">
                  <div class="sm:min-h-1/4 pt-8 pr-6 pb-12 pl-6 sm:w-full bg-red-600 w-3/5 cell discounts-section-body" style="background-size: cover; background-repeat: no-repeat; background-position: center; background-image: url('https://via.placeholder.com/700x400')">
                  </div>
                  <div class="bg-transparent sm:w-full w-1/3 cell card-info-elements">
                    <div class="bg-red-600 text-white pt-6 pr-24 w-full h-full pb-6 pl-8 card-contact-body card-info-image">
                      <div class="card-info-content-white">
                        <p class="text-base font-bold leading-none uppercase card-info-title">Información</p>  
                        <div class="mt-2 card-info-description">
                          <p class="text-xl font-bold card-info-value-bold">Centro Comercial Los Castaños, 2do Piso. Francisco Morazán, Distrito Central Centro Comercial Los Castaños</p>
                        </div>
                        <div class="mt-2 card-info-description">
                          <p class="text-xl card-info-label">Teléfono</p>
                          <p class="text-xl -mt-2 card-info-value">2240-0909 ext 384182</p>
                        </div>
                        <div class="mt-2 card-info-description">
                          <p class="text-xl card-info-label">Validez de la promoción</p>
                          <p class="text-xl -mt-2 card-info-value">01/08/2019 al 31/12/2019</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>`,
    category: 'Secciones',
  },

};

export default SectionBuilderComponents;